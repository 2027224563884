export const environment = {
    production: true,
    defaultauth: 'awsCognito',
    domain: 'connect-staging.visiveonline.com',
    jwtHostUrl: 'api-staging.visiveonline.com',
    apiUrl: 'https://api-staging.visiveonline.com/api/',
    webSocket: '165.227.238.98',
    webSocketAuth: 'https://api-staging.visiveonline.com/broadcasting/auth',
    awsCognito: {
        userPoolId: "us-east-1_o9LF8zudu",
        appClientId: "4h9oel8dm6vbpfkph6h3ctltrc"
    }
};

import {Injectable} from '@angular/core';

import {AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';
import {HttpHeaders} from '@angular/common/http';


import {User} from '../models/auth.models';

@Injectable({providedIn: 'root'})

export class AwsCognitoAuthService{

    user: User;

    session: CognitoUserSession;

    public authtoken : string;

    constructor() {
    }

    public getJwtToken()
    {
        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };

        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    console.log(err.message || JSON.stringify(err));
                }
                else
                {
                    this.authtoken = session.getAccessToken().getJwtToken();
                }
            });
        }
        return this.authtoken;
    }

    public isLoggedIn() : boolean
    {
        var isAuth = false;

        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };

        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    console.log(err.message || JSON.stringify(err));
                }
                isAuth = session.isValid();
            });
        }
        return isAuth;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        let authenticationDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };

        let userPool = new CognitoUserPool(poolData);

        let userData = { Username: email, Pool: userPool };

        var cognitoUser = new CognitoUser(userData);
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result: CognitoUserSession) => {
                    this.session = result;
                    this.authtoken = result.getAccessToken().getJwtToken();
                    resolve(result);

                    this.refreshtoken();
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    }

    bootupGetSession(){
        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };

        var userPool = new CognitoUserPool(poolData);
        var cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    console.log(err.message || JSON.stringify(err));
                }

                this.session = session;
            });
        }
    }

    refreshtoken(){
        setTimeout(function() {
            console.log('refreshing session');

            const poolData = {
                UserPoolId: environment.awsCognito.userPoolId,
                ClientId: environment.awsCognito.appClientId
            };

            const userPool = new CognitoUserPool(poolData);
            const cognitoUser = userPool.getCurrentUser();

            if (cognitoUser !== null)
            {
                cognitoUser.refreshSession(this.session.getRefreshToken(), (err, session) => {
                    if (err) {
                        console.log(err.message || JSON.stringify(err));
                    }

                    this.session = session;
                });

                this.refreshtoken();
            }
        }.bind(this), 600000);
    }

    /**
     * Logout the user
     */
    logout() {
        let poolData = {
            UserPoolId: environment.awsCognito.userPoolId,
            ClientId: environment.awsCognito.appClientId
        };
        let userPool = new CognitoUserPool(poolData);
        let cognitoUser = userPool.getCurrentUser();
        cognitoUser?.signOut();
    }
}


import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';

import {environment} from '../environments/environment';

import {NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CarouselModule} from 'ngx-owl-carousel-o';
import { NgxEchartsModule } from 'ngx-echarts';

import {ExtrapagesModule} from './extrapages/extrapages.module';

import {LayoutsModule} from './layouts/layouts.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ErrorInterceptor} from './core/helpers/error.interceptor';
import {JwtInterceptor} from './core/helpers/jwt.interceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.domain // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    position: 'bottom-left',
    theme: 'edgeless',
    palette: {
        popup: {
            background: '#29232f',
            text: '#ffffff',
            link: '#ffffff'
        },
        button: {
            background: '#f1d600',
            text: '#000000',
            border: 'transparent'
        }
    },
    type: 'info',
    content: {
        message: 'We uses cookies to ensure you get the best experience on V-Connect.',
        dismiss: 'Got it!',
        deny: 'Refuse cookies',
        link: 'Learn more',
        href: 'https://cookiesandyou.com',
        policy: 'Cookie Policy'
    },
    enabled: true,
};


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        LayoutsModule,
        AppRoutingModule,
        ExtrapagesModule,
        CarouselModule,
        NgbAccordionModule,
        NgbNavModule,
        NgbTooltipModule,
        NgbModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgbDropdownModule,
        NgcCookieConsentModule.forRoot(cookieConfig)
    ],
    bootstrap: [AppComponent],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ],
})
export class AppModule {
}

import {Injectable} from '@angular/core';
import { environment } from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';

import {AwsCognitoAuthService} from './aws-cognito-auth.service';

import { User, Role } from  '../models/auth.models';
import { Address } from '../models/address.models';
import {Company, CompanyUserPublicInvite} from '../models/company.models';
import {PermissionType} from "../models/permission.model";

import * as Sentry from "@sentry/browser";


export interface UserResponse {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    job_title: string;
    phone: string;
    address_id: number;
    address: Address;
    company_id: number;
    company: Company;
    roles: Role[];
    merged_permissions?: PermissionType[];
}

export interface UserRelatedCompanies {
    company_id: number;
    name: string;
    type: string;
    phone: string;
    admin_first_name: string;
    admin_last_name: string;
    admin_phone: number;
    admin_email: Address;
}

export interface UserRelatedDevices{
    id: number;
    name: string;
    serial_number: string;
    site_id: number;
    company_id: number;
    users_permissions: boolean | string;
}

@Injectable({providedIn: 'root'})
export class UserService {
    constructor(private http: HttpClient, private authenticationService: AwsCognitoAuthService) {
    }

    companyUrl = environment.apiUrl + 'company/';
    usersUrl = environment.apiUrl + 'users/';
    static company_id : number = 0;
    static permissions : PermissionType[] = [];
    static roles: Role[] = [];

    getUser(id: number) {
        return this.http.get<{ data: UserResponse }>(this.usersUrl + id, { withCredentials: true });
    }

    getCurrentUser() {
        return this.http.get<{ data: UserResponse }>(this.usersUrl + 'user', { withCredentials: true })
            .pipe(
                map(user => {
                    UserService.permissions = user.data.merged_permissions;
                    UserService.company_id = user.data.company_id;
                    UserService.roles = user.data.roles;
                    Sentry.setUser({
                        id: user.data.id,
                        username: user.data.first_name + ' ' + user.data.last_name,
                        email: user.data.email,
                    });
                    return user;
                }
            ));
    }

    getCurrentUsersRoles(){
        return UserService.roles;
    }

    getCurrentUsersPermissions(){
        return UserService.permissions;
    }

    getCurrentUsersCompanyId(){
        return UserService.company_id;
    }

    getCurrentUsersRelatedCompanies(){
        return this.http.get<{ data: UserRelatedCompanies[] }>(this.usersUrl + 'user/related-companies', { withCredentials: true });
    }

    getCurrentUsersRelatedDevices(){
        return this.http.get<{ data: UserRelatedDevices[] }>(this.usersUrl + 'user/related-devices', { withCredentials: true });
    }

    getCompanyUsers() {
        return this.http.get<{ data: UserResponse[] }>(this.companyUrl + 'users', { withCredentials: true });
    }

    getCompanyDeletedUsers() {
        return this.http.get<{ data: UserResponse[] }>(this.companyUrl + 'users/deleted', { withCredentials: true });
    }

    update(id: number, firstName: string, lastName: string, jobTitle: string, phone: string,
           house: string, street: string, city: string, county: string, zip: string, country: string) {
        const params = {
            first_name: firstName,
            last_name: lastName,
            job_title: jobTitle,
            phone,
            house,
            street,
            city,
            county,
            zip,
            country,
        };

        return this.http.patch(this.usersUrl + id, params);
    }

    updateCurrentUser(firstName: string, lastName: string, jobTitle: string, phone: string,
                      house: string, street: string, city: string, county: string, zip: string, country: string) {
        const params = {
            first_name: firstName,
            last_name: lastName,
            job_title: jobTitle,
            phone,
            house,
            street,
            city,
            county,
            zip,
            country,
        };

        return this.http.patch(this.usersUrl + 'user', params);
    }

    updateCurrentUsersCompany(companyId: number)
    {
        const params = {
            company_id: companyId
        };

        return this.http.patch(this.usersUrl + 'user/company', params);
    }

    updateCurrentUsersPassword(currentPassword: string, newPassword: string, newPasswordConfirmation: string)
    {
        const params = {
            current_password: currentPassword,
            new_password: newPassword,
            new_password_confirmation: newPasswordConfirmation,
        };

        return this.http.patch(this.usersUrl + 'user/password', params);
    }

    requestForgotPasswordLink(email: string)
    {
        const params = {
            email
        };

        return this.http.post(this.usersUrl + 'forgot-password', params);
    }

    confirmForgotPasswordLink(code: string, email: string, password: string, password_confirmation: string)
    {
        const params = {
            email,
            password,
            password_confirmation,
        };

        return this.http.post(this.usersUrl + 'forgot-password/' + code, params);
    }

    deleteUser(userId: number)
    {
        return this.http.delete(this.usersUrl + userId);
    }

    removeInvite(inviteId: number)
    {
        return this.http.delete(this.usersUrl + 'invitation/' + inviteId);
    }

    createInvite(email: string, role_id: number)
    {
        const params = {
            email,
            role_id
        };
        return this.http.post(this.usersUrl + 'invitation', params);
    }

    createCurrentUser(inviteToken: string, password: string, passwordConfirm: string,
                      firstName: string, lastName: string, jobTitle: string, phone: string,
                      house: string, street: string, city: string, county: string, zip: string, country: string) {
        const params = {
            password,
            password_confirmation: passwordConfirm,
            first_name: firstName,
            last_name: lastName,
            job_title: jobTitle,
            phone,
            house,
            street,
            city,
            county,
            zip,
            country,
        };

        return this.http.post(this.usersUrl + 'register/' + inviteToken, params);
    }

    getPublicInviteInfo(inviteToken: string) {
        return this.http.get<{ data: CompanyUserPublicInvite }>(this.usersUrl + 'register/' + inviteToken);
    }

    recoverUser(userId: number)
    {
        const params = {};
        return this.http.patch(this.usersUrl + userId + '/recover', params);
    }

    register(user: User) {
       // return this.http.post(`/users/register`, user);
    }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ActiveFirmwareDeployment, DeployFirmware, FirmwareVersions, PastFirmwareDeployments} from '../models/firmware.model';
import {NotificationChannels, NotificationMarkRead, NotificationPreferences, Notifications} from "../models/notification.model";
import {BehaviorSubject, Observable} from "rxjs";
import {catchError, mergeMap, shareReplay, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    //private preferencesCache: new BehaviorSubject(null);
    public preferencesCache: BehaviorSubject<{data: NotificationPreferences[]}>
        = new BehaviorSubject<{data: NotificationPreferences[]}>(null);

    constructor(private http: HttpClient) {
        const url = environment.apiUrl + "notifications/preferences";

        this.http.get<{data: NotificationPreferences[]}>(url).subscribe((data) => {
            this.preferencesCache.next(data);
        });
    }

    get(unread: boolean = false){
        const url = environment.apiUrl + "notifications" + ((unread) ? "?unread" : "");
        return this.http.get<{data: Notifications[]}>(url);
    }

    markRead(uuid: string = "*"){
        const url = environment.apiUrl + "notifications/read";

        return this.http.post<{data: NotificationMarkRead}>(url, {
            keys: [ uuid ]
        });
    }

    preferences(forceRefresh = false) : Observable<{data: NotificationPreferences[]}>{
        const url = environment.apiUrl + "notifications/preferences";

        if(!forceRefresh && this.preferencesCache != null)
            return this.preferencesCache.asObservable();

        this.http.get<{data: NotificationPreferences[]}>(url).subscribe((data) => {
            this.preferencesCache.next(data);
        });

        return this.preferencesCache.asObservable();
    }

    configPreferences(type: string, state: boolean, id: string = null, via: NotificationChannels[] = [])
        : Observable<{data: boolean}>{
        const url = environment.apiUrl + "notifications/preferences";

        return this.http.patch<{data: boolean}>(url, {
            type,
            state,
            id,
            via
        }).pipe(tap(x => {
            this.preferences(true);
        }));
    }
}
